<template>
    <div class="page" id="reorganize">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="type">
                <el-input size="small" maxlength="66" v-model="searchForm.description" placeholder="请输入展览名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <div class="f_r m_r1">
                <el-button type="primary" v-if="hasPermission('sys:exhibition:add')"  @click="view('add', null)" size="small" icon="el-icon-plus">新建</el-button>
                <el-button @click="fieldSet" size="small" v-if="hasPermission('sys:exhibition:card')" :disabled="dataListSelections.length<=0">卡片字段批量设置</el-button>
            </div>
        </el-form>
        <div class="top bg-white">
<!--            <el-button  type="danger" size="small" icon="el-icon-delete" @click="del()" :disabled="dataListSelections.length <= 0" plain>删除</el-button>-->
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="exhibitionId" label="展览编号"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionName" label="展览名称"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="exhibitionUserName" label="策展人"  show-overflow-tooltip></el-table-column>
                <el-table-column prop="beginDatetime" label="展览开始时间" sortable show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.beginDatetime">{{common.formatDate(scope.row.beginDatetime)}}</template>
                </el-table-column>
                <el-table-column prop="endDatetime" label="展览结束时间" sortable show-overflow-tooltip>
                    <template slot-scope="scope" v-if="scope.row.endDatetime">{{common.formatDate(scope.row.endDatetime)}}</template>
                </el-table-column>
                <el-table-column prop="exhibitionPlaceValue" label="展览地点" show-overflow-tooltip>
<!--                    <template slot-scope="scope">-->
<!--                        {{ $dictUtils.getDictLabel("exhibition_place", scope.row.exhibitionPlaceValue, '-') }}-->
<!--                    </template>-->
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="250">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" plain @click="view('view', scope.row)">查看</el-button>
                        <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                        <el-button size="mini" type="primary" plain @click="user(scope.row)">用户</el-button>
                        <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!-- 弹窗, 新增 / 修改 -->
        <AddForm ref="addForm" @refreshDataList="refreshList"></AddForm>
        <User ref="user" @refreshDataList="refreshList"></User>
        <!--卡片字段批量设置-->
        <el-dialog title="发布字段设置" width="50%" :visible.sync="visible">
            <el-transfer :titles="['发布字段可见列表', '发布字段隐藏列表']"
                         :filter-method="filterMethod"
                         filter-placeholder="请输入关键字"
                         :props="{key: 'key', label: 'exhibitionAttribute'}"
                         v-model="fieldVal"
                         :data="fieldData" @change="transferChange">
            </el-transfer>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="fieldSetShow" v-noMoreClick>确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import AddForm from './addForm'
    import User from './user'

    export default {
        data() {
            return {
                searchForm: {
                    description: ''
                },
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false,
                // 发布字段设置
                visible: false,
                fieldData: [
                    {
                        key:1,
                        informationIds:"",
                        exhibitionAttribute:"展览编号",
                        exhibitionField:"exhibitionId",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:2,
                        exhibitionAttribute:"展览名称",
                        exhibitionField:"exhibitionName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:3,
                        exhibitionAttribute:"主题词",
                        exhibitionField:"efieldName13",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:4,
                        exhibitionAttribute:"主办方",
                        exhibitionField:"efieldName15",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:5,
                        exhibitionAttribute:"策展人",
                        exhibitionField:"exhibitionUserName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:6,
                        exhibitionAttribute:"展览类型",
                        exhibitionField:"exhibitionTypeValue",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:7,
                        exhibitionAttribute:"展览开始时间",
                        exhibitionField:"beginDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:8,
                        exhibitionAttribute:"展览结束时间",
                        exhibitionField:"endDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                ],
                fieldVal: [],
                filterMethod(query, item) {
                    return item.exhibitionField.indexOf(query) > -1;
                }
            }
        },
        components: {
            AddForm, User
        },
        activated() {
            this.refreshList()
        },
        methods: {
            search() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.refreshList();
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.zlzs.informationList, {
                    'exhibitionName': this.searchForm.description,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        console.log(data);
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 发布字段设置
            fieldSet() {
                this.fieldVal = [];
                this.fieldData = [
                    {
                        key:1,
                        informationIds:"",
                        exhibitionAttribute:"展览编号",
                        exhibitionField:"exhibitionId",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:2,
                        exhibitionAttribute:"展览名称",
                        exhibitionField:"exhibitionName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:3,
                        exhibitionAttribute:"主题词",
                        exhibitionField:"efieldName13",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:4,
                        exhibitionAttribute:"主办方",
                        exhibitionField:"efieldName15",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:5,
                        exhibitionAttribute:"策展人",
                        exhibitionField:"exhibitionUserName",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:6,
                        exhibitionAttribute:"展览类型",
                        exhibitionField:"exhibitionTypeValue",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:7,
                        exhibitionAttribute:"展览开始时间",
                        exhibitionField:"beginDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                    {
                        key:8,
                        exhibitionAttribute:"展览结束时间",
                        exhibitionField:"endDatetime",
                        exhibitionValue:"",
                        attributeFlag:0,
                    },
                ]
                this.visible = true;
            },
            transferChange(val,direction,list){
                this.fieldData.forEach(function (v) {
                    list.forEach(function (k) {
                        if(v.key == k){
                            if(direction=='right'){
                                v.attributeFlag = 1
                            }else{
                                v.attributeFlag = 0
                            }
                        }
                    })
                })
                // console.log(this.fieldData);
            },
            // 发布字段显示与隐藏
            fieldSetShow() {
                // console.log(this.fieldData);
                this.fieldData[0].informationIds = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$axios(this.api.zlzs.SysExhibitionCardSave,
                    this.fieldData, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success(data.msg)
                        this.visible = false
                    }else{
                        this.$message.error(data.msg)
                    }
                })
            },
            // 用户
            user(row) {
                this.$refs.user.init(row)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 查看
            view(method, row) {
                this.$refs.addForm.init(method, row)
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.informationRemove, {'ids': ids}, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 重置
            resetSearch() {
                this.searchForm.description = ""
                // this.$refs.searchForm.resetFields()
                this.search()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style>
    .el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
        font-size: 13px !important;
    }
</style>
